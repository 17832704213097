<template>
    <div>
        <div class="mt-n6 div-form-status">
            <v-container>
                <v-row>
                    <v-col>
                        <label class="d-block yellow--text text--darken-3 font-weight-bold">เช็คสถานะสินค้า</label>
                    </v-col>
                </v-row>
                <v-row>
                    <v-spacer></v-spacer>
                    <v-col class="px-3" cols="12" md="8">
                    <v-text-field 
                        prepend-inner-icon="mdi-magnify"
                        placeholder="กรอกเลข Order Number"
                        v-model="search"
                        @keydown.enter="updateSearch"
                        solo>
                        <template v-slot:append>
                            <v-btn class="pa-6 mr-n3" depressed color="yellow darken-3" @click="updateSearch">
                                เช็คสถานะสินค้า
                            </v-btn>
                        </template>
                    </v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
            </v-container>
        </div>
        <div class="pb-16" style="background-color: #000000">
            <v-container v-if="job != null">
                <v-row>
                    <v-col md="6" cols="12">
                        <div class="ma-10">
                            <v-img
                            width="100%"
                            height="100%"
                            src="/images/order_found.png"
                            ></v-img>
                        </div>
                        
                    </v-col>
                    <v-col class="div-progress" md="6" cols="12">
                        <v-row>
                            <v-col>
                                <div class="d-block">
                                    <label class="yellow--text text--darken-3 font-weight-bold">
                                        สถานะพัสดุของคุณ
                                    </label>
                                </div>
                                <div class="d-block mt-10 mb-5">
                                    <v-progress-linear 
                                        rounded
                                        height="8"
                                        :value="statusNumber" 
                                        background-color="grey darken-4"
                                        color="yellow darken-3">
                                    </v-progress-linear>
                                    <!-- 7 28 50 72 100 -->
                                </div>
                                <div class="d-flex justify-space-between">
                                    <div>
                                        <label :class="{'yellow--text text--darken-3' : statusNumber >=7, 'grey--text text--darken-1' : statusNumber < 7}">รายการใหม่</label>
                                        <div v-if="status == 'new'" class="mt-2" style="position: absolute; margin-left: -30px;">
                                            <div class="box-tooltip pa-1" style="">
                                                <label class="mx-auto text-tooltip"> รายการพึ่งมาใหม่ </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <label :class="{'yellow--text text--darken-3' : statusNumber >=28, 'grey--text text--darken-1' : statusNumber < 28}">รออนุมัติ</label>
                                        <div v-if="'wait_confirm' == status || 'wait_confirm_with_approval' == status" class="mt-2" style="position: absolute; margin-left: -40px;">
                                            <div class="box-tooltip pa-1" style="">
                                                <label class="mx-auto text-tooltip"> กำลังตรวจสอบข้อมูล </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <label :class="{'yellow--text text--darken-3' : statusNumber >=50, 'grey--text text--darken-1' : statusNumber < 50}">ที่ต้องจัดส่ง</label>
                                        <div v-if="status=='wait_messenger'" class="mt-2" style="position: absolute; margin-left: -35px;">
                                            <div class="box-tooltip pa-1" style="">
                                                <label class="mx-auto text-tooltip"> พนักงานกำลังเข้าไปรับสินค้า </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <label :class="{'yellow--text text--darken-3' : statusNumber >=72, 'grey--text text--darken-1' : statusNumber < 72}">กำลังจัดส่ง</label>
                                        <div v-if="status == 'sending'" class="mt-2" style="position: absolute; margin-left: -35px;">
                                            <div class="box-tooltip pa-1" style="">
                                                <label class="mx-auto text-tooltip"> แมสกำลังไปส่งให้ลูกค้า </label>
                                            </div>
                                        </div>
                                    </div>

                                     <div>
                                        <label :class="{'yellow--text text--darken-3' : statusNumber == 100, 'grey--text text--darken-1' : statusNumber < 100}">ส่งมอบแล้ว</label>
                                        <div v-if="status == 'sent'" class="mt-2" style="position: absolute; margin-left: -35px;">
                                            <div class="box-tooltip pa-1" style="">
                                                <label class="mx-auto text-tooltip"> ลูกค้า ได้รับสินค้าแล้ว </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <div class="px-1 px-sm-5">
                            <label class="d-block font-weight-bold white--text">รายละเอียด</label>
                        </div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="6">
                        <div class="mx-1 mx-sm-5 border-right" style="text-align: left;">
                            <label class="d-block grey--text text--lighten-1">หมายเลขคำสั่งซื้อ: {{ code }}</label>
                            <label class="d-block grey--text text--lighten-1">วันที่: {{ createdAt }} </label>
                            <label class="d-block grey--text text--lighten-1">ชื่อ: {{ customer.name }}</label>
                            <label class="d-block grey--text text--lighten-1">ที่อยู่: {{ customer.address }}</label>
                            <label class="d-block grey--text text--lighten-1">เบอร์ติดต่อ: {{ customer.mobile }}</label>
                            <label class="d-block grey--text text--lighten-1">อีเมล: {{ customer.email }}</label>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <div class="mx-1 mx-sm-5" style="text-align: left;">
                            <label class="d-block grey--text text--lighten-1">เจ้าหน้าที่จัดส่ง: {{ messenger.name }}</label>
                            <label class="d-block grey--text text--lighten-1">เบอร์ติดต่อ: {{ messenger.mobile }}</label>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
            <v-container v-else>
                <div class="text-center pt-10">
                    <label class="text-h6 font-weight-bold white--text">ไม่พบรายการของคุณ</label>
                    <v-img
                        class="mx-auto mt-5"
                        src="/images/order_not_found.png"
                        width="200"
                        contain
                    ></v-img>
                </div>
            </v-container>
        </div>
        <dialog-error
            ref="dialogError"
            :topic="'Error'"
            :text="errorMessage"
        />
    </div>
</template>

<script>
import axios from 'axios';
import DialogError from '../components/DialogError.vue';
// import moment from 'moment';
import { thaiDateTime } from '../utils/text-mapping';

export default {
    
    data: () => ({
        show: true,
        search: '',
        statusNumber: 0,
        status: '',
        job: null,
        errorMessage: '',
        messenger: {}
    }),
    methods: {
        updateSearch(){
            const code = this.search.trim();
            if(!code){
                return ;
            }
            this.$router.push({ name: 'Status', query: { code : code } }).catch(() => {});
        },
        async checkStatus(){
            const loader = this.$loading.show();
            try{
                const queryCode = this.$route.query.code;
                if(!queryCode){
                    return;
                }
                this.search = queryCode;
                const response = await axios.get(process.env.VUE_APP_BASE_URL + '/jobs/status?code=' + this.search);
                this.job = response.data.job;
                this.transformMessenger(response.data.messenger);
                if(this.job != null){
                    this.status = this.job.ordering.status;
                    //  7, 28 50 72 100
                    if('new' == this.status){
                        this.statusNumber = 7;
                    }else if('wait_confirm' == this.status || 'wait_confirm_with_approval' == this.status){
                        this.statusNumber = 28;
                    }else if('wait_messenger' == this.status){
                        this.statusNumber = 50;
                    }else if('sending' == this.status){
                        this.statusNumber = 72;
                    }else if('sent' == this.status){
                        this.statusNumber = 100;
                    }else if('cancel' == this.status){
                        this.job = null;
                    }else{
                        this.statusNumber = 0;
                    }
                }
                
            }catch(error){
                this.errorMessage = error.response ? Array.isArray(error.response.data) ? error.response.data[0].msg : error.response.data : error.message;
                this.$refs.dialogError.show();
            }finally{
                loader.hide();
            }
        },
        transformMessenger(messenger){
            this.messenger = messenger ? {
                name: messenger.name,
                mobile: `${messenger.mobile.substring(0, 3)}-${messenger.mobile.substring(3, messenger.mobile.length)}`
            } : {};
        }
    },
    computed: {
        code(){
            return this.job ? this.job.code : '';
        },
        customer(){
            return this.job && this.job.customer ? {
                name: this.job.customer.name,
                address: this.job.customer.address,
                email: this.job.customer.email,
                mobile: `${this.job.customer.mobile.substring(0, 3)}-${this.job.customer.mobile.substring(3, this.job.customer.mobile.length)}`
            } : {};
        },
        createdAt(){
            return this.job ? thaiDateTime(new Date(this.job.ordering.createdAt)) : '';
        }
    },
    mounted(){
        this.checkStatus();
    },
    watch:{
        '$route'(){
            this.checkStatus();
        }
    },
    components: {
        DialogError
    }
};
</script>
<style scoped>
    .div-form-status, .div-progress{
        height: 300px;
        text-align: center;
        display: flex;
        align-items: center;
    }
    .border-right{
        border-right: 1px solid #666666;
    }
    .text-tooltip{
        /* <!-- -6% 16% 37% 59% 81%--> */
        display: inline-table;
        border: 1px solid #666666;
        border-radius: 4px;
        padding: 4px;
    }
    .box-tooltip{
        display: flex;
        position: relative;
        max-width: 140px;
        text-align: center;
        color: #aaaaaa;
        height: 50px;
    }

    .box-tooltip::after {
        content: "";
        position: relative;
        top: -100%;
        left: -48%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent #666666 transparent;
    }

    @media (max-width: 600px){
        .border-right{
            border-right: none;
        }
    }
</style>